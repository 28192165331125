import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  margin-bottom: 100px;

  .slider-image {
    height: 700px;
    position: relative;

    @media (max-width: 425px) {
      height: 300px;
    }
  }

  .slider-image::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(to top, #151515ff, #15151500);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .prev {
    left: 10px;
  }

  .next {
    right: 10px;
  }

  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: #d9d9d9;
  }
`;

export const GalleryHeader = styled.h1`
  max-width: 1200px;
  margin: 46px auto;
  font-size: 36px;
  font-family: "GothamPro", sans-serif;
  padding: 0 20px;
`;

export const Content = styled.div`
  background-color: #151515;
`;

export const ContentButton = styled.div`
  background-color: #151515;
  height: 159px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerForm = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;
