import {
  Container,
  ContentIcon,
  ContentText,
  Icon,
  Subtitle,
  Title,
} from "./styles";

const Benefits = ({ icon, title, subtitle }) => {
  return (
    <Container>
      <ContentIcon>
        <Icon alt="icon" src={icon} />
      </ContentIcon>

      <ContentText>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </ContentText>
    </Container>
  );
};

export default Benefits;
