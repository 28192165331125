import { useEffect, useState } from "react";

import { Container, Content, FillBar, Number, Text } from "./styles";

const ProgressBar = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(89);
  }, []);
  return (
    <Container>
      <Content>
        <FillBar width={`${width}%`}>
          <Number>{width}%</Number>
        </FillBar>
        <Text>VAGAS PREENCHIDAS</Text>
      </Content>
    </Container>
  );
};

export default ProgressBar;
