import { Container, Logo } from "./styles";

import LogoCaioba from "../../../assets/campaign/logo.svg";

const Header = () => {
  return (
    <Container>
      <Logo alt="logo" src={LogoCaioba} />
    </Container>
  );
};

export default Header;
