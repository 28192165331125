import styled from "styled-components";

export const Container = styled.section`
  min-height: 590px;
  height: 100%;
  display: flex;
  background-color: #151515;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const ContentText = styled.div`
  max-width: 700px;
  width: 100%;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  text-align: end;
  gap: 20px;

  @media (max-width: 768px) {
    justify-content: center;
    padding: 20px 20px;
    text-align: center;
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  color: white;
  font-family: "GothamPro", sans-serif;

  @media (max-width: 425px) {
    font-size: 34px;
  }
`;

export const Description = styled.p`
  color: white;
  font-family: "GothamPro", sans-serif;
  font-size: 21px;
  font-weight: 300;

  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  max-width: 500px;
  width: 100%;
  gap: 10px;

  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerForm = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
