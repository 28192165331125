import { Container, Icon } from "./styles";

import IconButton from "../../../assets/icons/icon_button.svg";

const ButtonWithIcon = ({ children, width, onClick, backgroundColor }) => {
  return (
    <Container
      style={{ width: width, backgroundColor: backgroundColor }}
      onClick={onClick}
    >
      <Icon alt="icone" src={IconButton} />
      {children}
    </Container>
  );
};

export default ButtonWithIcon;
