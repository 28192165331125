import Benefits from "../cards/benefits";
import { Container, Content, ImageContainer, List, Title } from "./styles";

import IconTshirt from "../../../assets/icons/tshirt.svg";
import IconCleats from "../../../assets/icons/cleats.svg";
import IconStar from "../../../assets/icons/star.svg";
import IconRacket from "../../../assets/icons/racket.svg";
import IconGift from "../../../assets/icons/gift.svg";
import Banner from "../../../assets/campaign/banner2.webp";

const data = [
  {
    id: 1,
    title: "Kit de camisas",
    subtitle: "Exclusivas para inscritos e seus papais",
    icon: IconTshirt,
  },
  {
    id: 2,
    title: "Kit de jogo completo para inscritos",
    subtitle: "Chuteira, meião, shorts, etc",
    icon: IconCleats,
  },
  {
    id: 3,
    title: "7 dias em um Hotel Paradisíaco All Inclusive",
    subtitle: "A ilha de Comandatuba te espera com toda a sua magia",
    icon: IconStar,
  },
  {
    id: 4,
    title: "Atividades para toda a família",
    subtitle:
      "Campeonatos de beach tennis, clínicas de tênis, jogo dos pais, jogo das mães e muito mais!",
    icon: IconRacket,
  },
  {
    id: 5,
    title: "Brindes exclusivos de parceiros",
    subtitle:
      "Nossos parceiros oficiais sempre preparam vários presentes incríveis para nossas famílias",
    icon: IconGift,
  },
];

const WhatIsIncluded = () => {
  return (
    <Container>
      <Content>
        <Title>O que está incluso nas suas férias?</Title>
        <List>
          {data.map((benefit) => (
            <Benefits
              key={benefit.id}
              title={benefit.title}
              subtitle={benefit.subtitle}
              icon={benefit.icon}
            />
          ))}
        </List>
      </Content>
      <ImageContainer>
        <img src={Banner} alt="Família jogando futebol" />
      </ImageContainer>
    </Container>
  );
};

export default WhatIsIncluded;
