import { Container, Content, Icon, Subtitle, Title } from "./styles";

const Feature = ({ title, subtitle, icon }) => {
  return (
    <Container>
      <Content>
        <div>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </div>

        <Icon alt="icone" src={icon} />
      </Content>
    </Container>
  );
};

export default Feature;
