import Feature from "../cards/feature";
import Form from "../form";

import {
  Container,
  Content,
  ContentFeatures,
  ContentForm,
  Title,
  Cards,
} from "./styles";

import IconPeople from "../../../assets/icons/activities.svg";
import IconInteraction from "../../../assets/icons/interaction.svg";
import IconConnection from "../../../assets/icons/connection.svg";
import IconAmbiance from "../../../assets/icons/ambiance.svg";

const Features = () => {
  return (
    <Container>
      <Content>
        <ContentFeatures>
          <Title>O que você vai viver no maior soccer camp do mundo</Title>

          <Cards>
            <Feature
              icon={IconPeople}
              title="Atividades"
              subtitle="para a família toda curtir."
            />
            <Feature
              icon={IconInteraction}
              title="Interação"
              subtitle="com grandes estrelas do futebol e influencers."
            />
            <Feature
              icon={IconConnection}
              title="Conexão"
              subtitle="genuína entre pais e filhos."
            />
            <Feature
              icon={IconAmbiance}
              title="Ambiente"
              subtitle="cheio de energia positiva."
            />
          </Cards>
        </ContentFeatures>
        <ContentForm>
          <Form backgroundColor="#292623" />
        </ContentForm>
      </Content>
    </Container>
  );
};

export default Features;
