import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 130px;
  border: 1px solid #f3b000;
  padding: 20px 20px;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  div {
    height: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-family: "GothamPro", sans-serif;
  color: #1d1d1b;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-family: "GothamPro", sans-serif;
  color: #1d1d1b;
  font-weight: 300;
`;

export const Icon = styled.img`
  width: 70px;
  height: 70px;
`;
