import styled from "styled-components";

export const Container = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  margin-bottom: 105px;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-family: "GothamPro", sans-serif;
  color: #1d1d1b;
  margin-bottom: 39px;
`;

export const Content = styled.div`
  height: 100%;
  max-width: 1200px;
  width: 100%;
`;

export const ContentCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;
