import styled from "styled-components";

// Estilização
export const Container = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  height: 811px;
  margin-bottom: 92px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
  }
`;

export const Content = styled.div`
  background-color: #f8f8f8;
  width: 50%;
  height: 738px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 20px;

  @media (max-width: 1024px) {
    width: 55%;
  }

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    align-items: center;
    height: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  font-family: "GothamPro", sans-serif;
  margin-bottom: 37px;
  max-width: 557px;
  width: 100%;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 557px;
  width: 100%;
  height: 100%;
`;

export const BenefitItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

export const BenefitText = styled.div`
  & h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 0.2rem 0;
  }

  & p {
    font-size: 1rem;
    color: #666;
    margin: 0;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  img {
    width: 70%;
    height: 811px;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
`;
