import { Container, Icon, Image, Informations, Name, Text } from "./styles";

import IconPeople from "../../../../assets/icons/people.svg";
import IconBedroom from "../../../../assets/icons/bedroom.svg";
import IconSquaremeters from "../../../../assets/icons/square_meters.svg";

const Bedroom = ({ name, imageBedroom, people, bedroom, squaremeters }) => {
  return (
    <Container>
      <Name>{name}</Name>
      <Image alt="imagem_quarto" src={imageBedroom} />

      <Informations>
        <div>
          <Icon alt="icone" src={IconPeople} />
          <Text>{people}</Text>
        </div>
        <div>
          <Icon alt="icone" src={IconBedroom} />
          <Text>{bedroom}</Text>
        </div>
        <div>
          <Icon alt="icone" src={IconSquaremeters} />
          <Text>{squaremeters}</Text>
        </div>
      </Informations>
    </Container>
  );
};

export default Bedroom;
