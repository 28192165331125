import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  height: auto;
`;

export const ContentIcon = styled.figure`
  width: 70px;
  height: 70px;
  background-color: #f3b000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  width: auto;
  height: auto;
`;

export const ContentText = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-family: "GothamPro", sans-serif;
  color: #151515;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-family: "GothamPro", sans-serif;
  color: #151515;
`;
