import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const FormSelect = ({ control, array, nameSelect, label }) => {
  return (
    <Controller
      control={control}
      name={nameSelect}
      rules={{ required: true }}
      defaultValue=""
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth sx={{ mb: 2 }} required>
          <InputLabel>{label}</InputLabel>
          <Select
            name={nameSelect}
            value={value || ""}
            onChange={onChange}
            label={label}
            MenuProps={{
              style: { zIndex: 99999 },
            }}
          >
            {array.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default FormSelect;
