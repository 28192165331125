import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  padding: 20px 20px;
`;

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ContentFeatures = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  padding-top: 35px;
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  color: #1d1d1b;
  font-family: "GothamPro", sans-serif;
`;

export const ContentForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: -200px;

  @media (max-width: 768px) {
    margin-top: 0px;
    justify-content: center;
  }
`;
