import { Container, ContainerButton, Content, Subtitle, Title } from "./styles";

import ButtonWithIcon from "../button";

const Banner = () => {
  const focusAndScroll = () => {
    document.querySelector("#form").scrollIntoView({ behavior: "smooth" });
    document.querySelector("#name").focus();
  };

  return (
    <Container>
      <Content>
        <Title>As férias que sua família vai contar pra sempre!</Title>
        <Subtitle>
          Transforme seu janeiro em Comandatuba na experiência mais emocionante
          do ano.
        </Subtitle>

        <ContainerButton>
          <ButtonWithIcon onClick={() => focusAndScroll()}>
            QUERO VIVER O CAIOBA!
          </ButtonWithIcon>
          <ButtonWithIcon
            backgroundColor="#A87E0F"
            onClick={() =>
              document.querySelector("#tables").scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            VER VALORES
          </ButtonWithIcon>
        </ContainerButton>
      </Content>
    </Container>
  );
};

export default Banner;
