import styled from "styled-components";

import Background from "../../../assets/campaign/banner.webp";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  background-color: #151515;
  background: linear-gradient(to right, #151515 5%, transparent 100%),
    url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 10px solid #f3b000;
  display: flex;
  justify-content: center;
  background-position: right;
  padding: 0 20px;
`;

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  min-height: 650px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    min-height: 450px;
  }
`;

export const Title = styled.h1`
  font-size: 60px;
  font-family: "GothamPro", sans-serif;
  color: white;
  max-width: 575px;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 425px) {
    max-width: 250px;
  }
`;

export const Subtitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: white;
  max-width: 575px;
  width: 100%;
  font-family: "GothamPro", sans-serif;
  margin-bottom: 61px;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 425px) {
    max-width: 250px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  max-width: 500px;
  width: 100%;
  gap: 10px;

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;
