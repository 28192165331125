import styled from "styled-components";

export const Container = styled.div`
  width: 386px;
  height: 496px;
  border: 1px solid #f3b000;
`;

export const Name = styled.h1`
  background-color: #f3b000;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "GothamPro", sans-serif;
  font-size: 23px;
`;

export const Image = styled.img`
  width: 100%;
  height: 204px;
  margin-bottom: 35px;
`;

export const Informations = styled.div`
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const Icon = styled.img`
  width: 36px;
  height: 19px;
`;

export const Text = styled.p`
  font-size: 14px;
  color: #151515;
  font-weight: 500;
  font-family: "GothamPro", sans-serif;
`;
