import { useState } from "react";
import CustomVideoPlayer from "../../video";
import ButtonWithIcon from "../button";
import Form from "../form";

import {
  Container,
  ContainerButton,
  ContainerForm,
  ContentText,
  Description,
  Title,
} from "./styles";

const EventMedia = () => {
  const [modal, setModal] = useState(false);

  return (
    <Container>
      <CustomVideoPlayer />

      <ContentText>
        <Title>
          No coração da Bahia, o Caioba combina futebol, diversão e conexão.{" "}
        </Title>
        <Description>
          Uma experiência única que une crianças, famílias e estrelas do futebol
          em um ambiente de alegria e emoção.
        </Description>
        <Description>
          {" "}
          Aqui, cada momento é uma lembrança para toda a vida.
        </Description>

        <ContainerButton>
          <ButtonWithIcon onClick={() => setModal(true)}>
            QUERO VIVER O CAIOBA!
          </ButtonWithIcon>
          <ButtonWithIcon
            backgroundColor="#A87E0F"
            onClick={() =>
              document.querySelector("#tables").scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            VER VALORES
          </ButtonWithIcon>
        </ContainerButton>
        <ContainerForm style={{ display: modal ? "flex" : "none" }}>
          <Form modal={true} onClick={() => setModal(false)} />
        </ContainerForm>
      </ContentText>
    </Container>
  );
};

export default EventMedia;
