import React, { useState } from "react";
import Slider from "react-slick";

import ButtonWithIcon from "../button";
import Form from "../form";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Image1 from "../../../assets/campaign/01.webp";
import Image2 from "../../../assets/campaign/02.webp";
import Image3 from "../../../assets/campaign/03.webp";
import Image4 from "../../../assets/campaign/04.webp";
import Image5 from "../../../assets/campaign/05.webp";
import Image6 from "../../../assets/campaign/06.webp";
import Image7 from "../../../assets/campaign/07.webp";
import Image8 from "../../../assets/campaign/08.webp";
import Image9 from "../../../assets/campaign/09.webp";
import Image10 from "../../../assets/campaign/10.webp";
import Image11 from "../../../assets/campaign/11.webp";
import Image12 from "../../../assets/campaign/12.webp";
import Image13 from "../../../assets/campaign/13.webp";
import Image14 from "../../../assets/campaign/14.webp";
import Image15 from "../../../assets/campaign/15.webp";
import Image16 from "../../../assets/campaign/16.webp";
import Image17 from "../../../assets/campaign/17.webp";
import Image18 from "../../../assets/campaign/18.webp";
import Image19 from "../../../assets/campaign/19.webp";
import Image20 from "../../../assets/campaign/20.webp";
import Image21 from "../../../assets/campaign/21.webp";
import Image22 from "../../../assets/campaign/22.webp";
import Image23 from "../../../assets/campaign/23.webp";
import Image24 from "../../../assets/campaign/24.webp";

import ArrowNext from "../../../assets/icons/arrow_next.svg";
import ArrowPrev from "../../../assets/icons/arrow_prev.svg";

import {
  Container,
  ContainerForm,
  Content,
  ContentButton,
  GalleryHeader,
} from "./styles";

const images = [
  { id: 1, image: Image1 },
  { id: 2, image: Image2 },
  { id: 3, image: Image3 },
  { id: 4, image: Image4 },
  { id: 5, image: Image5 },
  { id: 6, image: Image6 },
  { id: 7, image: Image7 },
  { id: 8, image: Image8 },
  { id: 9, image: Image9 },
  { id: 10, image: Image10 },
  { id: 11, image: Image11 },
  { id: 12, image: Image12 },
  { id: 13, image: Image13 },
  { id: 14, image: Image14 },
  { id: 15, image: Image15 },
  { id: 16, image: Image16 },
  { id: 17, image: Image17 },
  { id: 18, image: Image18 },
  { id: 19, image: Image19 },
  { id: 20, image: Image20 },
  { id: 21, image: Image21 },
  { id: 22, image: Image22 },
  { id: 23, image: Image23 },
  { id: 24, image: Image24 },
];

const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <img src={ArrowNext} alt="icone" />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <img src={ArrowPrev} alt="icone" />
    </div>
  );
};

const Carousel = () => {
  const [modal, setModal] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Container>
      <GalleryHeader>Galeria de fotos</GalleryHeader>
      <Content>
        <Slider {...settings}>
          {images.map((image) => (
            <div key={image.id} className="slider-image">
              <img src={image.image} alt={`Slide ${image.id}`} />
            </div>
          ))}
        </Slider>

        <ContentButton>
          <ButtonWithIcon onClick={() => setModal(true)}>
            QUERO VIVER O CAIOBA!
          </ButtonWithIcon>
          <ButtonWithIcon
            backgroundColor="#A87E0F"
            onClick={() =>
              document.querySelector("#tables").scrollIntoView({
                behavior: "smooth",
              })
            }
          >
            VER VALORES
          </ButtonWithIcon>
        </ContentButton>

        <ContainerForm style={{ display: modal ? "flex" : "none" }}>
          <Form modal={true} onClick={() => setModal(false)} />
        </ContainerForm>
      </Content>
    </Container>
  );
};

export default Carousel;
