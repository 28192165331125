import styled from "styled-components";

export const Container = styled.header`
  height: 153px;
  background-color: #151515;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
`;

export const Logo = styled.img`
  width: 292px;
  height: 90px;
`;
