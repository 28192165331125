import { Container, Content, ContentCards, Title } from "./styles";

import Standard from "../../../assets/campaign/standard.webp";
import Bangalo from "../../../assets/campaign/bangalo.webp";
import Luxo from "../../../assets/campaign/luxo.webp";
import Bangalo_luxo from "../../../assets/campaign/bangalo_luxo.webp";
import Premium from "../../../assets/campaign/premium.webp";
import Bedroom from "../cards/bedroom";

const data = [
  {
    id: 1,
    image: Standard,
    name: "Standard",
    people: "Até 4 pessoas, desde que 1 ou 2 pessoas tenham até 17 anos",
    bedroom: "1 cama casal (queen)  + 2 de solteiro ou 2 cama casal (queen)",
    squaremeters: "32m² + 5m² (terraço)",
  },
  {
    id: 2,
    image: Bangalo,
    name: "Bangalô Standard",
    people: "Até 4 pessoas, desde que 1 ou 2 pessoas tenham até 17 anos",
    bedroom: "01 cama de casal queen + 01 cama de solteiro.",
    squaremeters: "24m²+ 12m² (varanda)",
  },
  {
    id: 3,
    image: Luxo,
    name: "Luxo",
    people: "Até 4 pessoas",
    bedroom:
      "1 cama casal (king size) + 2 de solteiro ou 2 cama casal (king size)",
    squaremeters: "38m² + 5m² (terraço)",
  },
  {
    id: 4,
    image: Bangalo_luxo,
    name: "Bangalô luxo",
    people: "Até 04 pessoas, desde que 1 ou 2 pessoas tenham até 17 anos",
    bedroom:
      "1 cama casal (king size) ou 1 casal (king size) + 2 camas de solteiro",
    squaremeters: "36m² + 10m² (varanda)",
  },
  {
    id: 5,
    image: Premium,
    name: "Apartamento Premium",
    people: "Até 3 pessoas (2 adultos e 1 criança ou 1adulto + 2 crianças*)",
    bedroom: "01 cama casal king size (cama extra para a criança)",
    squaremeters: "37m² + 5m² (terraço)",
  },
];

const Bedrooms = () => {
  return (
    <Container>
      <Content>
        <Title>Quartos</Title>

        <ContentCards>
          {data.map((bedroom) => {
            return (
              <Bedroom
                key={bedroom.id}
                imageBedroom={bedroom.image}
                name={bedroom.name}
                people={bedroom.people}
                bedroom={bedroom.bedroom}
                squaremeters={bedroom.squaremeters}
              />
            );
          })}
        </ContentCards>
      </Content>
    </Container>
  );
};

export default Bedrooms;
