import styled from "styled-components";

export const Container = styled.div`
  background-color: #f3b000;
  height: 70px;
  padding: 43px 65px 43px 65px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  background-color: #d9d9d9;
  width: 100%;
  height: 35px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "GothamPro", sans-serif;

  & > span {
  }
`;

export const Number = styled.span`
  position: relative;
  z-index: 1;
`;

export const FillBar = styled.div`
  position: relative;
  height: 100%;
  width: ${(props) => props.width || "0%"};
  background: linear-gradient(to right, white, #f62919);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  transition: width 0.6s ease-in-out;

  & > span {
  }
`;

export const Text = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: bold;
  color: black;

  @media (max-width: 768px) {
    font-size: 17px;
  }
`;
