import styled from "styled-components";

export const Container = styled.form`
  max-width: 539px;
  width: 100%;
  height: 650px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  justify-content: space-evenly;
  position: relative;
  text-align: start;

  select {
    z-index: 99999;
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

export const ContentTitle = styled.div`
  color: #1d1d1b;
  font-family: "GothamPro", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: start;

  h1 {
    font-size: 36px;
    width: 100%;
  }

  span {
    font-size: 16px;
  }
`;

export const ContentClose = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  padding: 10px;
`;
