import styled from "styled-components";

export const Container = styled.button`
  max-width: 349px;
  width: 100%;
  height: 100%;
  background-color: #f3b000;
  display: flex;
  align-items: center;
  border: none;
  height: 68px;
  font-family: "GothamPro", sans-serif;
  font-size: 16px;
  color: white;
  cursor: pointer;
  justify-content: center;
  gap: 5px;
  font-weight: bold;
  padding: 30px;
`;

export const Icon = styled.img`
  width: 30px !important;
  height: 30px !important;
`;
