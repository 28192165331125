import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextField } from "@mui/material";
import Swal from "sweetalert2";

import Button from "../../button";
import MaskedTextField from "../../maskedField";
import LoadingForm from "../../loadingForm";
import FormSelect from "../../form/select";

import { Container, ContentClose, ContentTitle } from "./styles";

import { IoMdClose } from "react-icons/io";

const Form = ({ modal, onClick, backgroundColor = "#25D366" }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [isModal, setIsModal] = useState(modal);
  const [loading, setLoading] = useState(false);

  const recaptcha = async () => {
    return new Promise((resolve, reject) => {
      if (!window.grecaptcha) {
        return reject(new Error("grecaptcha is not loaded"));
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LcX-ooqAAAAADXMAuXRPydJjRiHmh1srtFiOrRZ", {
            action: "submit",
          })
          .then(resolve)
          .catch(reject);
      });
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const token = await recaptcha();

      const response = await fetch("https://api.caiobasc.com.br/campaign", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          token: token,
        }),
      });

      const result = await response.json();

      if (result.success === true) {
        Swal.fire({
          text: "Formulário enviado!",
          icon: "success",
          cancelButtonColor: "#f3b000",
          confirmButtonColor: "#f3b000",
          showCancelButton: true,
          confirmButtonText: "Entrar em contato",
          cancelButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "https://wa.me/+5511930590732";
          }
        });

        return;
      }

      Swal.fire({
        text: "Erro ao enviar formulário",
        icon: "error",
        confirmButtonColor: "#f3b000",
      });
    } catch (error) {
      Swal.fire({
        text: "Erro ao enviar formulário",
        icon: "error",
        confirmButtonColor: "#f3b000",
      });
    } finally {
      setLoading(false);
      reset();
    }
  };

  const checkLength = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const select = ["WhatsApp", "Ligação", "Email"];

  return (
    <Container onSubmit={handleSubmit(onSubmit)} id="form">
      {loading && <LoadingForm />}
      {isModal && (
        <ContentClose>
          <IoMdClose size={25} onClick={onClick} />
        </ContentClose>
      )}

      <ContentTitle>
        <h1>Receba um contato </h1>
        <span>
          Preencha o formulário abaixo e aguarde o contato de um dos nossos
          especialistas.
        </span>
      </ContentTitle>
      <TextField
        fullWidth
        label="Nome"
        variant="outlined"
        required
        {...register("name")}
        id="name"
      />

      <TextField
        label="Email"
        required
        variant="outlined"
        {...register("email", {
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Endereço de email inválido",
          },
        })}
        fullWidth
        error={!!errors.email}
        helperText={errors.email ? errors.email.message : null}
      />

      <TextField
        fullWidth
        label="Quantidade de pessoas na família"
        required
        {...register("quantityFamily")}
      />
      <Controller
        control={control}
        name="phone"
        rules={{
          required: true,
          validate: (value) => checkLength(value, 11) || "Telefone incompleto",
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <MaskedTextField
            mask="(99) 99999-9999"
            label="Telefone"
            value={value || ""}
            onChange={onChange}
            fullWidth
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <FormSelect
        array={select}
        control={control}
        nameSelect="contactMode"
        label="Por onde quer ser contatado"
      />

      <Button backgroundColor={backgroundColor}>ENVIAR</Button>
    </Container>
  );
};

export default Form;
